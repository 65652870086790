
const COLOR = {
    primary: '#282827',
    primaryDark: '#0F0F0F',
    primaryClear: '#FFEEB5',
    gold: '#C99B02',
    secondary: '#101439',
    secondaryClear: '#7278A8',
    background: '#FFFFF9',
    white: '#FFFFFF',
    black: '#333333',
    gray: '#999999',
    grayDark: '#777777',
    grayClear: '#EDEDED',
    redClear: '#ffd9da',
    red: '#FF6961',
    funBlue: '#1968B3'
}

const SIZE = {
    display: '36px',
    headline: '28px',
    title: '22px',
    subtitle: '18px',
    body: '16px',
    caption: '13px',
    small: '11px',
    row_margin: '80px 0',
    inp_radious: '3px' // Input border radious
}

const SCREEN_MEDIA = {
    small: `(min-width: 0px) and (max-width: 600px)`,
    medium : `(min-width: 600px) and (max-width: 992px)`,
    long: `(min-width: 992px) and (max-width: 1200px)`,
    xlong: `(min-width: 1200px)`
}

const LABEL = {
    about: 'About',
    us: 'Nosotros',
    contact: 'Contacto',
    tech: 'Tech'
}

const KEY = {
    emailJsService: 'service_dhqke3x',
    emailJsTemplate: 'template_g6k4irc',
    emailJsUserId: 'AWBxdjt66c08wgr0o'
}

export {
    COLOR,
    SIZE,
    SCREEN_MEDIA,
    LABEL,
    KEY
}