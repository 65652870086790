import React from 'react';
import styled from 'styled-components';
import { Container, Col, Parallax } from 'react-materialize';
import { COLOR, SIZE, SCREEN_MEDIA } from '../../utils/constants.js';
import Row from '../organism/row.jsx';
import RowInfo from '../organism/rowinfo.jsx';
import TitleSect from '../atoms/titlesect.jsx';
import CardContent from '../molecules/cardcontent.jsx';
import Divider from '../atoms/divider.jsx';
import Contact from '../organism/contact.jsx';

import PathImg01 from '../../images/img_01.jpg';
import PathImg02 from '../../images/img_02.jpg';
import PathImgBackground from '../../images/img_background.jpeg';

import PathIcAndroid from '../../icons/ic_android.svg';
import PathIcWorld from '../../icons/ic_world.svg';
import PathIcKotlin from '../../icons/ic_kotlin_black.svg';
import PathIcAndroidWear from '../../icons/ic_android_wear.svg';
import PathIcAndroidKoin from '../../icons/ic_android_koin.svg';
import PathIcReact from '../../icons/ic_react.svg';
import PathIcGit from '../../icons/ic_git.svg';
import PathIcAtlassian from '../../icons/ic_atlassian.svg';

import PathIcBriefcase from '../../icons/ic_briefcase.svg';
import PathIcCompany from '../../icons/ic_company.svg';
import PathIcGear from '../../icons/ic_gear.svg';
import PathIcCode from '../../icons/ic_code.svg';


const ParallaxStyled = styled(Parallax)`
    height: 1000px;
    margin: 60px 0;
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: relative;
    z-index: -1;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: ${COLOR.primary};
        opacity: 0.6;
    }
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 2450px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 1480px;
    }
`;

const ParallaxBody = styled(Container)`
    position: relative;
    z-index: 1;
`;

const BodyAbout = () => {
    return (
        <div>

            <Container>
                <RowInfo
                    margin='100px 0'
                    img_path={PathImg01}
                    img_right={false}
                    title='About me'
                    descs={["Hey, I am Ray Pasache.", "I grew up in Peru and I live in Barcelona, Catalonia - Spain.",
                    "When I started university I found out that I like to develop applications because I think that it " +
                    "can be entertaining. You can customise your own ideas and you execute then on a digital application" +
                    "that everyone may use. Furthermore, I like to make good aesthetic results to improve the user experience (UX). ",
                    "As a developer I would like to work on a software application to make the user’s life easier and improve the society " +
                    "through the digital development. In the long term, I would like to have my own company to execute my own ideas."]}/>
            </Container>

            <ParallaxStyled
                image={<img alt="" src={PathImgBackground}/>}
                options={{responsiveThreshold: 0}}
                children={
                    <ParallaxBody>
                        <Row>
                            <TitleSect color={COLOR.white}>Experience</TitleSect>
                        </Row>
                        <Row>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcAndroid} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Android' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["I enjoy doing applications for Android environment"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcKotlin} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Kotlin' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["In my opinion, the best POO language to develop Android applications"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcAndroidWear} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Android Wear' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["I enjoyed to work in Android Wear applications"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcAndroidKoin} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Koin' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["Improved my development experience to handle dependencies injection"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcKotlin} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Coroutines' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["The best way to manage async requests on any thread"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcGit} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Git' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["I handled each project with Git version control"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcAtlassian} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Atlassian' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["It's easier to manage the projects with Atlassian products"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcWorld} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Web' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["I like to do web projects for front-end side"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcReact} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='React' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["Doing components now is possible with React Library"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                        </Row>
                    </ParallaxBody>
                }
            />

            <Container>

                <RowInfo
                    margin='120px 0'
                    img_path={PathImg02}
                    img_right={false}
                    title='Projects'
                    descs={["I  have worked in different kind of project such as…"]}
                    bullet_items={[
                        { item: "Ametller - Shopping Android App maintenance" },
                        { item: "Catalana Occidente - Security Android App maintenance" },
                        { item: "InnoTest - Public examination Android App maintenance" },
                        { item: "BeYou - Health Android App maintenance" },
                        { item: "Stin - Android Watch App maintenance" },
                        { item: "Pet Health - Vet manager application." },
                        { item: "Android notes application." },
                        ]}/>

                <Divider/>

                <Row className='valign-wrapper' margin='40px 0 0 0'>
                    <Col s={12} m={12} l={12} xl={8} offset='xl2'>
                        <Contact/>
                    </Col>
                </Row>

                <Divider/>

                <Row margin='40px 0 40px 0'>
                    <Col s={12} m={6} l={6} xl={4}>
                        <CardContent 
                            ic_path={PathIcGear} 
                            ic_height='50px'
                            ic_color={COLOR.primaryDark}
                            title='7'
                            descs={['Projects']} 
                            alignment='center'
                            margin='30px 0px'/>
                    </Col>
                    <Col s={12} m={12} l={4} xl={4}>
                        <CardContent 
                            ic_path={PathIcCompany} 
                            ic_height='50px'
                            ic_color={COLOR.primaryDark}
                            title='2'
                            descs={["Job companies"]} 
                            alignment='center'
                            margin='30px 0px'/>
                    </Col>
                    <Col s={12} m={6} l={6} xl={4}>
                        <CardContent 
                            ic_path={PathIcCode} 
                            ic_height='50px'
                            ic_color={COLOR.primaryDark}
                            title='3'
                            descs={['Languages']} 
                            alignment='center'
                            margin='30px 0px'/>
                    </Col>
                </Row>
                
            </Container>
            
        </div>
    );
}

export default BodyAbout;