import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/organism/header.jsx';
import Footer from './components/organism/footer.jsx';
import BodyAbout from './components/templates/bodyabout.jsx';
import BodyTech from './components/templates/bodytech.jsx';
import BodyContact from './components/templates/bodycontact.jsx';
import { Root } from './utils/global.js';
import { LABEL } from './utils/constants.js';
import { 
    FontMonaco,
    FontSailecRegular, 
    FontSailecMedium, 
    FontSailecBold, 
    FontSailecLight, 
    FontHarmonyRegular, 
    FontHarmonyLite } from './utils/fonts.js';

const App = () => {
    return(
        <div>
            <Root/>
            <FontMonaco/>
            <FontSailecRegular/>
            <FontSailecMedium/>
            <FontSailecBold/>
            <FontSailecLight/>
            <FontHarmonyRegular/>
            <FontHarmonyLite/>

            <Router>
                <Routes>
                    <Route exact path='/' element={
                        <div>
                            <Header selected={LABEL.about}/>
                            <BodyAbout/>
                        </div>
                    }/>
                    <Route exact path='/tech' element={
                        <div>
                            <Header selected={LABEL.tech}/>
                            <BodyTech/>
                        </div>
                    }/>
                    <Route exact path='/contact' element={
                        <div>
                            <Header selected={LABEL.contact}/>
                            <BodyContact/>
                        </div>
                    }/>
                </Routes>
            </Router>
            <Footer/>
        </div>
    )
}
export default App;