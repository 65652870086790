import React from 'react';
import { Container } from 'react-materialize';
import { LABEL } from '../../utils/constants.js';
import RowInfo from '../organism/rowinfo.jsx';
import Banner from '../organism/banner.jsx';
import Divider from '../atoms/divider';

import PathImgAndNotesRay from '../../images/img_andnotes_ray.png';
import PathIcAndroidColored from '../../icons/ic_android_colored.svg';
import PathIcWorldSvg from '../../icons/ic_world.svg';
import PathIcGitColored from '../../icons/ic_git_colored.svg';
import PathImgBackground from '../../images/img_background.jpeg';


const BodyTech = () => {
    return(
        <div>
            <Banner img_path={PathImgBackground} title={LABEL.tech}/>

            <Container>
                <RowInfo
                    margin='60px 0'
                    img_path={PathImgAndNotesRay}
                    img_height='300px'
                    img_width='300px'
                    img_height_small='250px'
                    img_width_small='250px'
                    img_right={true}
                    title='Android'
                    bullet_items={[
                        { item: "Android Studio IDE" },
                        { item: "Kotlin Language" },
                        { item: "Java Language" },
                        { item: "Model-View-View-Model (MVVM) Architecture" },
                        { item: "Model-View-Presenter (MVP) Architecture" },
                        { item: "View-Interactor-Presenter-Entity-Router (VIPER)" },
                        { item: "Clean Architecture Design Pattern" },
                        { item: "Live Data Observables" },
                        { item: "Navigation component library" },
                        { item: "Navigation Safe Args" },
                        { item: "View Binding Library" },
                        { item: "Koin Dependency injection manager" },
                        { item: "Dagger Dependency injection manager" },
                        { item: "Retrofit Library" },
                        { item: "Kotlin Coroutines" },
                        { item: "Android Room" }, 
                        { item: "Gradle managment" },
                        { item: "Android Wear" }
                    ]}/>

                <Divider margin='60px 0 0 0'/>

                <RowInfo
                    margin='60px 0'
                    img_path={PathIcGitColored}
                    img_height='200px'
                    img_width='200px'
                    img_height_small='100px'
                    img_width_small='100px'
                    img_right={false}
                    title='Support'
                    bullet_items={[
                        { item: "Git Version Control" },
                        { item: "Atlassian Sourcetree" },
                        { item: "Atlassian Bitbucket" },
                        { item: "Atlassian Jira" },
                        { item: "Swagger" },
                        { item: "Firebase Crashlytics" },
                        { item: "Bitrise CI/CD" },
                        { item: "Postman" },
                        { item: "Figma" },
                        { item: "Sketch" },
                        { item: "Terminal" }
                    ]}/>

                <Divider margin='60px 0 0 0'/>

                <RowInfo
                    margin='60px 0'
                    img_path={PathIcWorldSvg}
                    img_height='120px'
                    img_width='120px'
                    img_height_small='100px'
                    img_width_small='100px'
                    img_right={false}
                    title='Web'
                    bullet_items={[
                        { item: "Visual Studio Code IDE" },
                        { item: "React PropTypes" },
                        { item: "React Router DOM Library" },
                        { item: "React Materialize Library" },
                        { item: "Styled Component Library" },
                        { item: "JavaScript Language" },
                        { item: "jQuery Language" }
                    ]}/>

            </Container>
        </div>
    );
}

export default BodyTech;