import React from 'react';
import styled from 'styled-components';
import { Container, Col } from 'react-materialize';
import { LABEL, COLOR } from '../../utils/constants.js';
import Row from '../organism/row.jsx';
import Divider from '../atoms/divider.jsx'
import Card from '../molecules/card.jsx';
import Banner from '../organism/banner.jsx';
import Path from '../molecules/path.jsx';
import Contact from '../organism/contact.jsx'
import TextIcon from '../atoms/texticon.jsx';
import { LabelMonaco } from '../atoms/label.jsx';

import PathImg4 from '../../images/img_04.jpg';
import PathIcDownload from '../../icons/ic_download.svg';

import PathCV from '../../files/CV_RAY.pdf';

const BodyContact = () => {
    return (
        <div>
            <Banner
                img_path={PathImg4}
                title={LABEL.contact}/>

            <Container>
                <Row
                    margin='0 0'>
                    <Path 
                        endpoint={LABEL.contact}
                        margin='20px 0' />
                </Row>
                <Row className='valign-wrapper'>
                    <Col s={12} m={12} l={12} xl={8} offset='xl2'>
                        <Contact/>
                    </Col>
                </Row>

                <Divider/>

                <Row>
                    <Col s={12} m={12} l={12} xl={12}>
                        <LabelMonaco
                            text_size='22px'>
                            Curriculum Vitae
                        </LabelMonaco>
                        <a href={PathCV} download>
                            <TextIcon 
                                ic_path={ PathIcDownload }
                                ic_color={ COLOR.funBlue }
                                ic_size='20px'
                                text_color={ COLOR.funBlue }
                                text_size='18px'
                                margin='20px 0 0 0'>
                                Download
                            </TextIcon>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BodyContact;